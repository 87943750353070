<script setup lang="ts">
import { useDisplay } from "vuetify";

const emit = defineEmits(["close"]);
const { mdAndUp } = useDisplay();

const config = useRuntimeConfig();
const zwapLink = config.public.zwapLink;
const zcscanLink = config.public.zcscanLink;
</script>

<template>
  <div class="megamenu-inner megamenu-margin">
    <div v-if="!mdAndUp" class="ma-3 d-flex justify-end">
      <div class="d-flex align-center" @click="emit('close')">
        <VIcon icon="custom-back" />
      </div>
    </div>
    <VRow>
      <VCol cols="12" md="4" class="text-center text-md-left megamenu-block">
        <div class="mb-5">
          <h4 class="mb-1 font-weight-bold">
            {{ $t("navbar.usezchains_second_title") }}
          </h4>
          <div class="megamenu-block-list-item" v-if="zwapLink">
            <NuxtLink class="list-link" :to="zwapLink" target="_blank">
              {{ $t("navbar.zwap") }}
            </NuxtLink>
          </div>
        </div>

        <div class="mb-5">
          <h4 class="mb-1 font-weight-bold">
            {{ $t("navbar.usezchains_third_title") }}
          </h4>
          <div class="megamenu-block-list-item" v-if="zcscanLink">
            <NuxtLink
              class="list-link"
              target="_blank"
              rel="noopener noreferrer"
              :to="zcscanLink"
            >
              {{ $t("navbar.zc_scan") }}
            </NuxtLink>
          </div>
        </div>
      </VCol>
    </VRow>
  </div>
</template>
